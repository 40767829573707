import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ProductItemReserve } from '../../../api/marketx';
import { Grid } from '@mui/material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { Link as MxLink, MxLabel } from '@mx-ui/ui';
import { ThreadLink } from '../../Bills/ThreadLink';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import Typography from '@mui/material/Typography';
import HdrAutoOutlinedIcon from '@mui/icons-material/HdrAutoOutlined';
import { billStateColor, paymentStateColor } from '../../Bills/BillListItem';
import { useTheme } from '@mui/material/styles';

interface ReserveItemHeaderProps {
  reserve: ProductItemReserve;
  documentNumber?: string;
  selectedWarehouse?: string;
}

// eslint-disable-next-line complexity
export const ReserveItemHeader: FC<ReserveItemHeaderProps> = observer(({ reserve, documentNumber, selectedWarehouse }): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:865px)');
  const backgroundColor =
    (documentNumber === reserve.billDocumentNumber && !!reserve.billDocumentNumber && !!documentNumber) ||
    (documentNumber === reserve.freezeDocumentNumber && !!reserve.freezeDocumentNumber && !!documentNumber)
      ? theme.palette.mode === 'dark'
        ? '#121212'
        : '#F5F5F5'
      : 'background.paper';
  const isSelectedWarehouse = selectedWarehouse ? reserve.warehouseCode === selectedWarehouse : true;
  const colorText = isSelectedWarehouse ? 'textPrimary' : 'textSecondary';
  const fontWeight = selectedWarehouse && isSelectedWarehouse ? 500 : undefined;
  return matches ? (
    <Grid container alignItems="center" pl={1} sx={{ backgroundColor: backgroundColor }}>
      {!!reserve.billDocumentNumber ? (
        <Grid item pr={1}>
          <Grid container alignItems="center">
            <Grid item pr={1} height={'24px'}>
              <CreditCardOutlinedIcon />
            </Grid>
            <Grid item>
              <MxLink
                href={`/app/bills/${reserve.billDocumentNumber}`}
                variant="body2"
                color={colorText}
                typographySx={{ fontWeight: documentNumber === reserve.billDocumentNumber ? 600 : fontWeight }}
              >
                {reserve.billDocumentNumber}
              </MxLink>
            </Grid>
            <Grid item>
              <ThreadLink link={`/app/thread/bills/${encodeURIComponent(reserve.billDocumentNumber)}`} />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {!!reserve.freezeDocumentNumber ? (
        <Grid item pr={1}>
          <Grid container alignItems="center">
            <Grid item pr={1} height={'24px'}>
              <AcUnitOutlinedIcon />
            </Grid>
            <Grid item>
              <MxLink
                href={`/app/freeze/${reserve.freezeDocumentNumber}`}
                variant="body2"
                color={colorText}
                typographySx={{ fontWeight: documentNumber === reserve.freezeDocumentNumber ? 600 : fontWeight }}
              >
                {reserve.freezeDocumentNumber}
              </MxLink>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {!!reserve.documentNumber && !reserve.billDocumentNumber && !reserve.freezeDocumentNumber ? (
        <Grid item pr={1}>
          <Grid container alignItems="center">
            <Grid item pr={1} height={'24px'}>
              <QuizOutlinedIcon />
            </Grid>
            <Grid item>
              <Typography variant="body1">{reserve.documentNumber}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {(reserve.dealEmployeeSurname || reserve.dealEmployeeName || reserve.dealEmployeePatronymic) && (
        <Grid item pr={1}>
          <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
            {`${reserve.dealEmployeeSurname || ''} ${reserve.dealEmployeeName || ''} ${reserve.dealEmployeePatronymic || ''}`.trim()}
          </Typography>
        </Grid>
      )}
      {reserve.autoReserveEnabled && (
        <Grid item height={'20px'} pr={1} title={'Авторезерв'}>
          <HdrAutoOutlinedIcon fontSize={'small'} />
        </Grid>
      )}
      {reserve?.billStateCode ? (
        <Grid item pr={1}>
          <Grid container alignItems="center">
            <Grid item pr={1}>
              <Typography fontSize={'12px'} color={'text.secondary'}>
                Статус
              </Typography>
            </Grid>
            <Grid item>
              <MxLabel color={billStateColor(reserve?.billStateCode)} style={{ fontSize: '10px' }}>
                {reserve?.billStateTitle}
              </MxLabel>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {reserve?.freezeDocumentNumber ? (
        <Grid item pr={1}>
          <Grid container alignItems="center">
            <Grid item pr={1}>
              <Typography fontSize={'12px'} color={'text.secondary'}>
                Статус
              </Typography>
            </Grid>
            <Grid item>
              <MxLabel color={'success'} style={{ fontSize: '10px' }}>
                Резерв подтвержден
              </MxLabel>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {reserve?.billPaymentStateCode ? (
        <Grid item pr={1}>
          <Grid container alignItems="center">
            <Grid item pr={1}>
              <Typography fontSize={'12px'} color={'text.secondary'}>
                Статус оплаты
              </Typography>
            </Grid>
            <Grid item>
              <MxLabel color={paymentStateColor(reserve?.billPaymentStateCode)} style={{ fontSize: '10px' }}>
                {reserve.billPaymentStateTitle}
              </MxLabel>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {reserve?.customerCode ? (
        <Grid item xs={12} container pr={1}>
          <MxLink
            href={`/app/clients/${reserve?.customerCode}`}
            variant="body2"
            color={colorText}
            typographySx={{ fontWeight: fontWeight }}
          >
            {reserve.customerShort || reserve.customerTitle || '-'}
          </MxLink>
        </Grid>
      ) : (
        (reserve.customerShort || reserve.customerTitle) && (
          <Grid item xs={12} container pr={1}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {reserve.customerShort || reserve.customerTitle}
            </Typography>
          </Grid>
        )
      )}
    </Grid>
  ) : (
    <Grid container alignItems="center" pl={1} sx={{ backgroundColor: backgroundColor }}>
      {!!reserve.billDocumentNumber ? (
        <Grid item pr={1}>
          <Grid container alignItems="center">
            <Grid item pr={1} height={'24px'}>
              <CreditCardOutlinedIcon />
            </Grid>
            <Grid item>
              <MxLink
                href={`/app/bills/${reserve.billDocumentNumber}`}
                variant="body2"
                color={colorText}
                typographySx={{ fontWeight: documentNumber === reserve.billDocumentNumber ? 600 : fontWeight }}
              >
                {reserve.billDocumentNumber || '-'}
              </MxLink>
            </Grid>
            <Grid item>
              <ThreadLink link={`/app/thread/bills/${encodeURIComponent(reserve.billDocumentNumber)}`} />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {!!reserve.freezeDocumentNumber ? (
        <Grid item pr={1}>
          <Grid container alignItems="center">
            <Grid item pr={1} height={'24px'}>
              <AcUnitOutlinedIcon />
            </Grid>
            <Grid item>
              <MxLink
                href={`/app/freeze/${reserve.freezeDocumentNumber}`}
                variant="body2"
                color={colorText}
                typographySx={{ fontWeight: documentNumber === reserve.freezeDocumentNumber ? 600 : fontWeight }}
              >
                {reserve.freezeDocumentNumber || '-'}
              </MxLink>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {!!reserve.documentNumber && !reserve.billDocumentNumber && !reserve.freezeDocumentNumber ? (
        <Grid item pr={1}>
          <Grid container alignItems="center">
            <Grid item pr={1} height={'24px'}>
              <QuizOutlinedIcon />
            </Grid>
            <Grid item>
              <Typography variant="body1">{reserve.documentNumber}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {(reserve.dealEmployeeSurname || reserve.dealEmployeeName || reserve.dealEmployeePatronymic) && (
        <Grid item pr={1}>
          <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
            {`${reserve.dealEmployeeSurname || ''} ${reserve.dealEmployeeName || ''} ${reserve.dealEmployeePatronymic || ''}`.trim()}
          </Typography>
        </Grid>
      )}
      {reserve.autoReserveEnabled && (
        <Grid item height={'20px'} pr={1} title={'Авторезерв'}>
          <HdrAutoOutlinedIcon fontSize={'small'} />
        </Grid>
      )}
      {reserve.typeCode && (
        <Grid item>
          <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
            ({reserve.typeDescription})
          </Typography>
        </Grid>
      )}
      {reserve?.customerCode ? (
        <Grid item xs={12} container pr={1}>
          <MxLink
            href={`/app/clients/${reserve?.customerCode}`}
            variant="body2"
            color={colorText}
            typographySx={{ fontWeight: fontWeight }}
          >
            {reserve.customerShort || reserve.customerTitle || '-'}
          </MxLink>
        </Grid>
      ) : (
        (reserve.customerShort || reserve.customerTitle) && (
          <Grid item xs={12} container pr={1}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {reserve.customerShort || reserve.customerTitle}
            </Typography>
          </Grid>
        )
      )}
    </Grid>
  );
});
