import React, { FC, JSX, forwardRef, useCallback, useEffect, useState } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { useForm, Controller, FieldError, ControllerRenderProps, useWatch } from 'react-hook-form';
import { diff } from 'deep-object-diff';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ruLocale from 'date-fns/locale/ru';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { observer } from 'mobx-react-lite';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TaskCommentItem, TaskCreateRequest, TaskEntityItem, TaskItemCanEdit, TaskLogItem } from 'src/api/marketx';
import { TasksStore } from 'src/store/TasksStore';
import { WYSIWYGEditor } from 'src/components/WYSIWYGEditor';
import { useAuthStore } from 'src/store/MobxStoreProvider';
import { iconByCodePriority } from '../KanbanBoard/PrioritiesIcon';
import { CommentBlock } from './CommentBlock';
import { TabPanel, StyledInnerTabs } from '@mx-ui/ui';
import { useRouter } from 'next/router';
import { TaskLogs } from './TaskLogs';
import useDebouncedFunction from '../../../hooks/useDebounceFunction';
import { DialogContainer } from '../../ui/dialogs/DialogContainer';
import { TaskDialogSkeleton } from './Skeleton';

export const CustomTextField: FC<any> = forwardRef((props: TextFieldProps & { errorForm: boolean }, ref): JSX.Element => {
  const { errorForm, ...other } = props;
  return <TextField {...other} error={errorForm || props.error} inputRef={ref} />;
});
const contactsTabsValue = [
  { label: `Комментарии`, tabPanelIndex: 0, name: 'comments', disabled: false },
  { label: `История`, tabPanelIndex: 1, name: 'history', disabled: false },
  // { label: `История взаимодействий и данных по клиенту из ТС`, tabPanelIndex: 1, disabled: false },
];
interface TaskDialogProps {
  open: boolean;
  onClose: () => void;
  store: TasksStore;
  onSave: (v: FormData) => Promise<void>;
  defaultValues?: FormData;
  isLoading?: boolean;
}

// export interface FormData extends TaskCreateRequest {
//   code?: string;
//   deadlineDate: Date | string;
//   beginDate: Date | string;
// }
export type FormData = Omit<TaskCreateRequest, 'deadlineDate' | 'beginDate' | 'description' | 'entities'> & {
  deadlineDate?: Date;
  beginDate?: Date;
  initiatorEmployeeCode?: string;
  description?: EditorState | string;
  followers?: string[];
  code?: string;
  canEdit?: TaskItemCanEdit;
  comments?: Array<TaskCommentItem>; //* не обновляется, но передается для блока комментариев
  logs?: Array<TaskLogItem>; //* не обновляется, но передается для блока комментариев
  entities?: Array<TaskEntityItem>; //* не обновляется, но передается для блока комментариев
};

export const defaultValuesForm: FormData = {
  deadlineDate: new Date(),
  statusCode: 'todo',
  title: '',
  initiatorEmployeeCode: '',
  executorEmployeeCode: '',
  description: EditorState.createWithContent(ContentState.createFromText('')),
  beginDate: new Date(),
  priorityCode: 'medium',
  followers: [],
};

//* https://react-hook-form.com/ru/get-started#IntegratingwithUIlibraries
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP * 2,
      width: 250,
    },
  },
};
const handleChangeLabels = (
  _event: React.SyntheticEvent<Element, Event>,
  newValue: any,
  field: ControllerRenderProps<FormData, 'labelCodes'>
): void => {
  field.onChange([...newValue]);
};

export const TaskDialog: FC<TaskDialogProps> = observer(({ open, onClose, defaultValues, onSave, store, isLoading }) => {
  const [isSaving, setIsSaving] = useState(false);
  const isCreate = !defaultValues?.code;
  const { priorities, statuses, labels } = store;
  const { asPath, push } = useRouter();
  const employees = store.employeesStore?.items ?? [];
  useEffect(() => {
    if (open) {
      if (isCreate) {
        if (store.employeesStore.request?.specialAction !== 'createTask') {
          store.employeesStore.setRequest({ specialAction: 'createTask' });
          store.employeesStore.loadEpmloyeesByAction();
        }
      } else {
        setToggleButtonValue(0);
        if (
          store.employeesStore.request?.specialAction !== 'updateTask' ||
          store.employeesStore.request.specialActionObjectKey !== defaultValues.code
        ) {
          store.employeesStore.setRequest({ specialAction: 'updateTask', specialActionObjectKey: defaultValues.code });
          store.employeesStore.loadEpmloyeesByAction();
        }
      }
    }
  }, [store.employeesStore, open]);

  const form = useForm({
    defaultValues: defaultValues ?? defaultValuesForm,
    mode: 'onSubmit',
  });

  const isMayEditAll = isCreate ? true : defaultValues?.canEdit?.all;
  const isReadOnly = !isMayEditAll;
  const isMayEditStatus = defaultValues?.canEdit ? defaultValues?.canEdit?.status : isMayEditAll;
  const isMayEditExecutor = defaultValues?.canEdit ? defaultValues?.canEdit?.executor : isMayEditAll;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = form;

  useEffect(() => {
    reset(defaultValues ?? defaultValuesForm);
  }, [defaultValues]);
  const deadlineDateValue = useWatch({ control, name: 'deadlineDate' });
  const executorEmployeeCodeValue = useWatch({ control, name: 'executorEmployeeCode' });
  const followersEmployeeCodeValue = useWatch({ control, name: 'followers' });
  const deletedFollowers = new Set();
  const addedFollowers = new Set();

  const handleChangeFollowersEmployeeCode = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: any,
    field: ControllerRenderProps<FormData, 'followers'>
  ): void => {
    field.onChange(newValue);
  };

  useEffect(() => {
    const newVal = followersEmployeeCodeValue ?? [];
    const defaultVal = defaultValues?.followers ?? [];
    if (newVal.length > defaultVal.length) {
      newVal.forEach(item => {
        if (!defaultVal.includes(item)) {
          addedFollowers.add(item);
          deletedFollowers.delete(item);
        } else {
          addedFollowers.delete(item);
          deletedFollowers.delete(item);
        }
      });
    } else if (newVal.length < defaultVal.length) {
      defaultVal.forEach(item => {
        if (!newVal.includes(item)) {
          addedFollowers.delete(item);
          deletedFollowers.add(item);
        } else {
          addedFollowers.delete(item);
          deletedFollowers.delete(item);
        }
      });
    }
    const deletedFollowersList = (Array.from(deletedFollowers) as string[]) ?? [];
    const addedFollowersList = (Array.from(addedFollowers) as string[]) ?? [];
    if (deletedFollowersList.length || addedFollowersList.length) {
      handleSubmitFollowers(deletedFollowersList, addedFollowersList, defaultValues?.code);
    }
  }, [followersEmployeeCodeValue]);

  const handleChangeExecutorEmployeeCode = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: any,
    field: ControllerRenderProps<FormData, 'executorEmployeeCode'>
  ): void => {
    field.onChange(newValue);
    if (!followersEmployeeCodeValue?.includes(defaultValues.executorEmployeeCode)) {
      setValue(
        'followers',
        followersEmployeeCodeValue?.length
          ? [...followersEmployeeCodeValue, defaultValues.executorEmployeeCode]
          : [defaultValues.executorEmployeeCode],
        {
          shouldDirty: true,
        }
      );
    }
  };

  const onSubmit = useCallback(
    (data: FormData): void => {
      //* в onSave отправляем лишь разницу между данными
      const diffValue = diff(defaultValues, data) as FormData;
      const dataNew: FormData = Object.keys(diffValue)
        .filter(i => typeof diffValue[i] === 'string')
        .reduce((acc, i) => {
          acc[i] = diffValue[i];
          return acc;
        }, {});

      if (diffValue.labelCodes) {
        dataNew.labelCodes = data.labelCodes;
      }
      if (dataNew.description === defaultValues.description) {
        dataNew.description = undefined;
      }

      if (data.code) {
        dataNew.code = data.code;
      }
      if (isCreate) {
        dataNew.title = data.title;
        dataNew.labelCodes = data.labelCodes;
        dataNew.description = data.description;
        dataNew.statusCode = data.statusCode;
        dataNew.priorityCode = data.priorityCode;
        dataNew.deadlineDate = data.deadlineDate;
        dataNew.beginDate = data.beginDate;
      } else {
        if (Boolean(data.deadlineDate) && defaultValues.deadlineDate.getTime() !== data.deadlineDate.getTime()) {
          dataNew.deadlineDate = data.deadlineDate;
        } else {
          dataNew.deadlineDate = undefined;
        }
        if (Boolean(data.beginDate) && defaultValues.beginDate.getTime() !== data.beginDate.getTime()) {
          dataNew.beginDate = data.beginDate;
        } else {
          dataNew.beginDate = undefined;
        }
      }
      if (data.entities) {
        dataNew.entities = data.entities;
      }
      setIsSaving(true);
      onSave(dataNew).finally(() => {
        setIsSaving(false);
      });
    },
    [defaultValues, onSave, isSaving]
  );
  const { profile } = useAuthStore();
  const isMayEditFollowersSelf = followersEmployeeCodeValue.includes(profile.employee.code) && !defaultValues.canEdit?.follower;

  const handleAppoinYourselfBtn = (): void => {
    setValue('executorEmployeeCode', profile.employee.code, { shouldDirty: true });
  };
  const handleAppoinYourselfToFollowers = (): void => {
    if (!followersEmployeeCodeValue?.includes(profile.employee.code)) {
      setValue(
        'followers',
        followersEmployeeCodeValue?.length ? [...followersEmployeeCodeValue, profile.employee.code] : [profile.employee.code],
        {
          shouldDirty: true,
        }
      );
    } else {
      const index = followersEmployeeCodeValue?.indexOf(profile.employee.code);
      const newFollowersList = [...followersEmployeeCodeValue.slice(0, index), ...followersEmployeeCodeValue.slice(index + 1)];
      setValue('followers', newFollowersList, {
        shouldDirty: true,
      });
    }
  };

  const handleClose = (): void => {
    onClose();
  };
  const handleCheckOnSameCustomer = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, entity: TaskEntityItem): void => {
    if (asPath.indexOf(entity.entityCode) === -1) {
      push(`/app/clients/${encodeURIComponent(entity.entityCode)}`);
      return;
    } else {
      e.preventDefault();
      onClose();
    }
  };
  const [toggleButtonValue, setToggleButtonValue] = useState(0);
  const handleToggleButtonChange = useCallback(
    (event: React.ChangeEvent<unknown>, newValue: number): void => {
      if (newValue !== null) {
        setToggleButtonValue(Number(newValue));
      }
    },
    [setToggleButtonValue]
  );

  const handleSubmitComment = (value: string): void => {
    store.addCommentForTask(defaultValues.code, { comment: value }).then(r => {
      store.addCommentForTaskLocal(defaultValues.code, r.data.comment);
    });
  };

  const handleSubmitFollowers = useDebouncedFunction((deletedFollowers: string[], addedFollowers: string[], code: string): void => {
    if (code && store && (deletedFollowers.length || addedFollowers.length)) {
      store
        .addTaskFollowers(code, addedFollowers, deletedFollowers)
        .then(r => {
          store.addFollowersForTaskLocal(code, r.data.task.followers);
        })
        .catch(e => {
          console.log('handleSubmitFollowers', e);
        });
    }
  }, 1000);

  const validateDateBegin = (value: Date): string => {
    return value.setHours(0, 0, 0, 0) > deadlineDateValue.setHours(0, 0, 0, 0)
      ? 'Дата начала должна быть равна или меньше даты окончания'
      : null;
  };

  const convertHTMLToEditorState = (markdown: string | EditorState): EditorState => {
    if (typeof markdown === 'string') {
      const blocksFromHTML = convertFromHTML(markdown);
      const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      return EditorState.createWithContent(state);
    }
    return markdown;
  };

  return (
    <DialogContainer
      open={open}
      onClose={handleClose}
      dialogTitle={isCreate ? 'Создание задачи' : `Просмотр задачи ${defaultValues?.code || ''}`}
      dialogContent={
        isLoading ? (
          <TaskDialogSkeleton isCreate={isCreate} />
        ) : (
          <Grid container flexDirection={'column'}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <Controller
                  name={'title'}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      helperText={errors.title?.type === 'required' && 'Поле обязательно для заполнения'}
                      error={!!errors.title}
                      inputProps={{ readOnly: isReadOnly }}
                      margin="dense"
                      sx={{ width: '100%' }}
                      label="Наименование"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={'statusCode'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    const isErrorRequired = errors.statusCode?.type === 'required';

                    return (
                      <FormControl fullWidth error={isErrorRequired}>
                        <InputLabel id="select-type-label-statuses">Статус</InputLabel>
                        <Select
                          labelId="select-type-label-statuses"
                          id="select-statuses"
                          label="Статус"
                          readOnly={!isMayEditStatus}
                          {...field}
                        >
                          {statuses.map(j => (
                            <MenuItem value={j.code} key={j.code}>
                              {j.title}
                            </MenuItem>
                          ))}
                        </Select>
                        {isErrorRequired && <FormHelperText>Поле обязательно для заполнения</FormHelperText>}
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={'priorityCode'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    const isErrorRequired = errors.priorityCode?.type === 'required';

                    return (
                      <FormControl fullWidth error={isErrorRequired}>
                        <InputLabel id="select-type-label-priorities">Приоритет</InputLabel>
                        <Select
                          labelId="select-type-label-priorities"
                          id="select-priorities"
                          label="Приоритет"
                          readOnly={isReadOnly}
                          {...field}
                          renderValue={(selected: string) => {
                            const priority = priorities.find(i => i.code === selected);
                            return (
                              <Box display={'flex'}>
                                {iconByCodePriority(priority?.code)} {priority?.title}
                              </Box>
                            );
                          }}
                        >
                          {priorities.map(j => (
                            <MenuItem value={j.code} key={j.code}>
                              <ListItemIcon>{iconByCodePriority(j.code)}</ListItemIcon>
                              <ListItemText> {j.title}</ListItemText>
                            </MenuItem>
                          ))}
                        </Select>
                        {isErrorRequired && <FormHelperText>Поле обязательно для заполнения</FormHelperText>}
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={'executorEmployeeCode'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    //*  https://github.com/react-hook-form/react-hook-form/issues/2922#issuecomment-693179435
                    const error = errors.executorEmployeeCode as FieldError;
                    const isErrorRequired = error?.type === 'required';
                    return (
                      <FormControl fullWidth error={isErrorRequired}>
                        <Autocomplete
                          value={field.value || null}
                          onChange={(e, data) => handleChangeExecutorEmployeeCode(e, data, field)}
                          readOnly={!isMayEditExecutor}
                          disableClearable
                          getOptionLabel={option => {
                            if (option) {
                              const contact = employees?.find(i => i.code === option);
                              return `${contact?.surname || ''} ${contact?.name || ''} ${contact?.patronymic || ''}`;
                            }
                          }}
                          options={employees.map(contact => contact.code)}
                          renderInput={params => <TextField error={isErrorRequired} {...params} label="Ответственный" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option}>
                                {/* @ts-ignore*/}
                                {props.key}
                              </li>
                            );
                          }}
                        />
                        {isErrorRequired && <FormHelperText>Поле обязательно для заполнения</FormHelperText>}
                        {isMayEditExecutor && profile.employee.code !== executorEmployeeCodeValue && (
                          <Link
                            component="button"
                            type="button"
                            sx={{ display: 'inline-flex', alignSelf: 'flex-end' }}
                            variant="body2"
                            onClick={handleAppoinYourselfBtn}
                          >
                            назначить себе
                          </Link>
                        )}
                      </FormControl>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name={'labelCodes'}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Autocomplete
                        {...field}
                        value={field.value || []}
                        readOnly={isReadOnly}
                        onChange={(e, data) => handleChangeLabels(e, data, field)}
                        multiple
                        id="free-solo-dialog-labels"
                        getOptionLabel={option => labels?.find(i => i.code === option)?.title}
                        options={labels.map(i => i.code)}
                        renderTags={tagValue =>
                          labels
                            ?.filter(i => tagValue.includes(i.code))
                            .map(option => (
                              <Chip
                                label={option.title}
                                sx={{
                                  color: option.textColor,
                                  bgcolor: option.color,
                                  m: 0.5,
                                }}
                                key={option.code}
                                size="small"
                                title={option.description}
                              />
                            ))
                        }
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        renderOption={(props, item) => {
                          const option = labels?.find(i => i.code === item);
                          return (
                            <li {...props}>
                              {option ? (
                                <Box title={option.description} display="flex" alignItems={'center'}>
                                  <Box
                                    sx={{ borderRadius: '50%', width: '16px', height: '16px', mr: 1, backgroundColor: option.color }}
                                  ></Box>
                                  <Typography variant="body2">{option.title}</Typography>
                                </Box>
                              ) : (
                                item
                              )}
                            </li>
                          );
                        }}
                        freeSolo
                        renderInput={params => <TextField {...params} label="Метки" />}
                      />
                    </>
                  )}
                />
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="beginDate"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        dateBeginLessDateEnd: validateDateBegin,
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        label="Начало"
                        format="yyyy-MM-dd"
                        slots={{
                          textField: CustomTextField,
                        }}
                        readOnly={isReadOnly}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            errorForm: !!errors.beginDate,
                            helperText:
                              (errors.beginDate?.type === 'required' && 'Поле обязательно для заполнения') || errors.beginDate?.message,
                          } as TextFieldProps,
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="deadlineDate"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <DatePicker
                        label="Окончание"
                        format="yyyy-MM-dd"
                        slots={{
                          textField: CustomTextField,
                        }}
                        readOnly={isReadOnly}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            errorForm: !!errors.deadlineDate,
                            helperText:
                              (errors.deadlineDate?.type === 'required' && 'Поле обязательно для заполнения') ||
                              errors.deadlineDate?.message,
                          } as TextFieldProps,
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                {!isCreate && (
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={'followers'}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => {
                        //*  https://github.com/react-hook-form/react-hook-form/issues/2922#issuecomment-693179435
                        const error = errors.followers as FieldError;
                        const isErrorRequired = error?.type === 'required';

                        return (
                          <FormControl fullWidth error={isErrorRequired}>
                            <Autocomplete
                              value={field.value || []}
                              onChange={(e, data) => handleChangeFollowersEmployeeCode(e, data, field)}
                              readOnly={!isMayEditExecutor}
                              disableClearable
                              multiple
                              getOptionLabel={option => {
                                if (option) {
                                  const contact = employees?.find(i => i.code === option);
                                  return `${contact?.surname || ''} ${contact?.name || ''} ${contact?.patronymic || ''}`;
                                }
                              }}
                              options={employees.map(contact => contact.code)}
                              renderInput={params => <TextField error={isErrorRequired} {...params} label="Наблюдатели" />}
                            />
                            {isErrorRequired && <FormHelperText>Поле обязательно для заполнения</FormHelperText>}
                            {!followersEmployeeCodeValue?.includes(profile.employee.code) && (
                              <Link
                                component="button"
                                type="button"
                                sx={{ display: 'inline-flex', alignSelf: 'flex-end' }}
                                variant="body2"
                                onClick={handleAppoinYourselfToFollowers}
                              >
                                назначить себе
                              </Link>
                            )}
                            {isMayEditFollowersSelf && (
                              <Link
                                component="button"
                                type="button"
                                sx={{ display: 'inline-flex', alignSelf: 'flex-end' }}
                                variant="body2"
                                onClick={handleAppoinYourselfToFollowers}
                              >
                                убрать себя
                              </Link>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                )}
              </LocalizationProvider>
              {!isCreate && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    name={'initiatorEmployeeCode'}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      const error = errors.initiatorEmployeeCode as FieldError;
                      const isErrorRequired = error?.type === 'required';

                      return (
                        <FormControl fullWidth error={isErrorRequired}>
                          <InputLabel>Инициатор</InputLabel>
                          <Select
                            renderValue={(selected: string) =>
                              employees
                                .filter(i => selected.includes(i.code))
                                .map(contact => `${contact.surname || ''} ${contact.name || ''} ${contact.patronymic || ''}`)
                                .join(', ')
                            }
                            MenuProps={MenuProps}
                            readOnly={true}
                            label="Инициатор"
                            {...field}
                            value={field.value || ''}
                          >
                            {employees.map(contact => (
                              <MenuItem value={contact.code} key={contact.code}>
                                {contact.surname || ''} {contact.name || ''} {contact.patronymic || ''}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
              )}
              {!isCreate && !!defaultValues?.entities?.length && (
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      borderColor: 'rgba(0, 0, 0, 0.25)',
                      height: 56,
                      justifyContent: 'flex-start',
                      fontSize: '14px',
                      textTransform: 'none',
                    }}
                    title={'Перейти'}
                    onClick={e => handleCheckOnSameCustomer(e, defaultValues?.entities[0])}
                  >
                    {defaultValues?.entities[0].entityTitle}
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={convertHTMLToEditorState(defaultValues?.description || '')}
                  render={({ field }) => (
                    <Box sx={{ border: '1px solid rgb(215, 215, 215)', padding: '4px', borderRadius: '4px' }}>
                      <WYSIWYGEditor
                        value={convertHTMLToEditorState(field.value)}
                        readOnly={isReadOnly}
                        onChange={editorState => field.onChange(editorState)}
                      />
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
            {!isCreate && (
              <Grid item xs={12}>
                <StyledInnerTabs
                  handleToggleButtonChange={handleToggleButtonChange}
                  toggleButtonValue={toggleButtonValue}
                  tabsValue={contactsTabsValue}
                />
                <TabPanel value={toggleButtonValue} index={0} paddingY={0}>
                  <CommentBlock onSubmitComment={handleSubmitComment} comments={defaultValues.comments || []} />
                </TabPanel>
                <TabPanel value={toggleButtonValue} index={1} paddingY={0}>
                  <TaskLogs logs={defaultValues.logs} />
                </TabPanel>
              </Grid>
            )}
          </Grid>
        )
      }
      canselBtnText={'Отмена'}
      successBtnText={'Сохранить'}
      handleSuccess={handleSubmit(onSubmit)}
      disabled={isSubmitting || !isDirty || isSaving}
      maxWidth={'md'}
    />
  );
});
