import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductItemReserve } from '../../../api/marketx';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { formatNumberByUnit, formatPriceCur, formatYmdHiLZ } from '@mx-ui/helpers';
import { MxLabel } from '@mx-ui/ui';
import { paymentStateColor } from '../../Bills/BillListItem';
import Divider from '@mui/material/Divider';

interface NotFormedReserveTableProps {
  reserve: ProductItemReserve;
  selectedWarehouse?: string;
}
const boxGridStyleSoftReserve = {
  display: 'grid',
  minWidth: '1050px',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gridGap: '4px',
  padding: '8px 0',
};
export const NotFormedReserveTable: FC<NotFormedReserveTableProps> = observer(({ reserve, selectedWarehouse }): JSX.Element => {
  const matches = useMediaQuery('(min-width:865px)');
  const titleHeight = '40px';
  const isSelectedWarehouse = selectedWarehouse ? reserve.warehouseCode === selectedWarehouse : true;
  const colorText = isSelectedWarehouse ? 'textPrimary' : 'textSecondary';
  const fontWeight = selectedWarehouse && isSelectedWarehouse ? 500 : undefined;
  return matches ? (
    <Box sx={boxGridStyleSoftReserve}>
      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Дата резерва
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
            {reserve.reservedAt ? formatYmdHiLZ(reserve.reservedAt) : '-'}
          </Typography>
        </Box>
      </Box>
      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Статус резерва
          </Typography>
        </Box>
        <Box>
          {reserve?.stateCode ? (
            <MxLabel color={paymentStateColor(reserve?.stateCode)} style={{ fontSize: '10px' }}>
              {reserve.stateTitle}
            </MxLabel>
          ) : (
            <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
              {reserve.stateTitle || ''}
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Резерв
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
            {`${formatNumberByUnit(reserve.quantity || 0, reserve.unitCode, reserve.unit || 'т')} из ${formatNumberByUnit(
              reserve.plannedQuantity || 0,
              reserve.unitCode,
              reserve.unit || 'т'
            )}`}
          </Typography>
        </Box>
      </Box>
      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Ожидание
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
            {formatNumberByUnit(reserve.awaitingQuantity || 0, reserve.unitCode, reserve.unit || 'т')}
          </Typography>
        </Box>
      </Box>
      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Дней ожидания
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
            {reserve.awaitingDays || ''}
          </Typography>
        </Box>
      </Box>
      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Цена с услугами
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
            {reserve.productCost ? formatPriceCur(reserve.productCost) : ''}
          </Typography>
        </Box>
      </Box>

      <Box display="grid">
        <Box minHeight={titleHeight}>
          <Typography fontSize={'12px'} color={'text.secondary'}>
            Склад
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
            {reserve.warehouseName}
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}> Дата резерва</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {reserve.reservedAt ? formatYmdHiLZ(reserve.reservedAt) : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}>Статус резерва</Typography>
          </Grid>
          <Grid item xs={6}>
            {reserve?.stateCode ? (
              <MxLabel color={paymentStateColor(reserve?.stateCode)} style={{ fontSize: '10px' }}>
                {reserve.stateTitle}
              </MxLabel>
            ) : null}
          </Grid>
        </Grid>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}>Резерв</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {`${formatNumberByUnit(reserve.quantity || 0, reserve.unitCode, reserve.unit || 'т')} из ${formatNumberByUnit(
                reserve.plannedQuantity || 0,
                reserve.unitCode,
                reserve.unit || 'т'
              )}`}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}>Ожидание</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {formatNumberByUnit(reserve.awaitingQuantity || 0, reserve.unitCode, reserve.unit || 'т')}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}>Дней ожидания</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {reserve.awaitingDays || ''}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}>Цена с услугами</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {reserve.productCost ? formatPriceCur(reserve.productCost) : ''}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={6}>
            <Typography variant={'body2'}>Склад</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
              {reserve.warehouseName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
