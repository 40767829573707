import React, { FC, JSX, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CorrectionShipment, SaleShipment } from '../../api/marketx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import { formatDateSwagger, formatDateSwaggerZ, formatNumber3 } from '@mx-ui/helpers';
import { ThreadLink } from '../Bills/ThreadLink';
import { AttentionBlock } from '../Clients/AttentionBlock/AttentionBlock';
import { renderPriceCur } from '../../utils/renderFormat';
import { MxLabel } from '@mx-ui/ui';
import { paymentStateColor } from '../Bills/BillListItem';
import { LabelNonResident } from 'src/views/clients/components/CompanyExtInfo';
import { ShipmentsListStore } from '../../store/Documents/ShipmentsListStore';
import IconButton from '@mui/material/IconButton';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import { Link as MxLink } from '@mx-ui/ui';
import useDocumentsPath from '../../hooks/useDocumentsPath';
import LightTooltip from '../ToolTip/LightTooltip';
import { ColorChangedPropertyDeal } from '../../views/deals/DealContainer';

interface ShipmentListItemShortenedProps {
  shipment: SaleShipment & CorrectionShipment;
  shipmentsListStore?: ShipmentsListStore;
  isCorrection?: boolean;
}

export const ShipmentListItemShortened: FC<ShipmentListItemShortenedProps> = observer(
  ({ shipment, shipmentsListStore, isCorrection }): JSX.Element => {
    const [tooltipType, setTooltipType] = useState('');
    const { asQueryPath } = useDocumentsPath('shipments');
    const matchesXs = useMediaQuery('(min-width:450px)');

    const handleCustomerChange = useCallback(
      (customerCode?: string) => {
        if (shipmentsListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        shipmentsListStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
      },
      [shipmentsListStore]
    );

    const handleCustomerRemoveChange = useCallback(
      (customerCode?: string) => {
        if (!shipmentsListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        const newCustomerCodes = shipmentsListStore.request?.customerCodes?.filter(i => i !== customerCode);
        shipmentsListStore.mergeRequest({ customerCodes: newCustomerCodes });
      },
      [shipmentsListStore]
    );

    const handleChangeFilterByPaymentStatus = useCallback(
      (status?: string) => {
        if (shipmentsListStore.request?.paymentStateCodes?.includes(status)) {
          return;
        }
        shipmentsListStore.mergeRequest({ paymentStateCodes: [status] });
      },
      [shipmentsListStore]
    );

    const handleRemovePaymentState = useCallback(
      (status?: string) => {
        if (!shipmentsListStore.request?.paymentStateCodes?.includes(status)) {
          return;
        }
        const newStatusCodes = shipmentsListStore.request?.paymentStateCodes?.filter(i => i !== status);
        shipmentsListStore.mergeRequest({ paymentStateCodes: newStatusCodes });
      },
      [shipmentsListStore]
    );

    const totalCostOld = shipment.positionsTotalCostOld;
    const totalCost = shipment.positionsTotalCost;

    const quantityTheReduceOld = shipment.positionsTotalWeightOld;
    const quantityTneReduce = shipment.positionsTotalWeight;
    return (
      <Paper
        key={shipment.shipmentCode}
        elevation={3}
        sx={{ display: 'grid', gridTemplateColumns: 'minmax(310px, 1fr)' }}
        className="withCustomScroll"
      >
        <Grid container p={1} justifyContent="space-between">
          <Grid item md={5} xs={12}>
            <Grid container justifyContent="space-between" mb={1}>
              <Grid container alignItems="center">
                <Grid item mr={1}>
                  <CardTravelOutlinedIcon fontSize="medium" color="primary" />
                </Grid>
                <Grid item>
                  <MxLink
                    href={`/app/shipments${isCorrection ? '/correction' : ''}/${encodeURIComponent(
                      shipment.documentNumber
                    )}?${asQueryPath}`}
                    color="primary"
                    typographySx={{ fontWeight: 600 }}
                  >
                    {`${shipment.documentNumber}${matchesXs ? ' от ' + formatDateSwagger(shipment.documentDate) : ''}`}
                  </MxLink>
                </Grid>

                <Grid item>
                  <ThreadLink color="primary" link={`/app/thread/shipments/${encodeURIComponent(shipment.documentNumber)}`} />
                </Grid>
              </Grid>
            </Grid>
            {Boolean(shipment?.recipientCustomer) && (
              <Grid container mb={2} flexDirection="column">
                <Grid item>
                  <Typography variant="body2">ИНН {shipment.recipientCustomer.inn || '—'}</Typography>
                </Grid>
                <Grid
                  item
                  sx={{ maxWidth: matchesXs ? 'auto' : '320px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  container
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                >
                  {!!shipment.recipientCustomer?.nonResident && (
                    <Grid item mr={1}>
                      <LabelNonResident />
                    </Grid>
                  )}
                  {!!shipment.recipientCustomer?.attentions?.length && (
                    <Grid item mr={1}>
                      <AttentionBlock attentions={shipment.recipientCustomer?.attentions} />
                    </Grid>
                  )}
                  <Grid item>
                    <MxLink
                      href={`/app/clients/${encodeURIComponent(shipment.recipientCustomer.code)}`}
                      color="text.secondary"
                      typographySx={{ fontSize: 15, fontWeight: 600 }}
                    >
                      {shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}
                    </MxLink>
                  </Grid>
                  {shipmentsListStore && shipment.recipientCustomer.code ? (
                    shipmentsListStore.request?.customerCodes?.includes(shipment.recipientCustomer.code) ? (
                      <Grid item ml={1}>
                        <IconButton
                          size={'small'}
                          onClick={() => handleCustomerRemoveChange(shipment.recipientCustomer.code)}
                          title={`Убрать фильтрацию по ${shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}`}
                        >
                          <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item ml={1}>
                        <IconButton
                          size={'small'}
                          onClick={() => handleCustomerChange(shipment.recipientCustomer.code)}
                          title={`Найти отгрузки ${shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}`}
                        >
                          <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Grid>
                    )
                  ) : null}
                </Grid>
              </Grid>
            )}
            <Grid container mb={1} justifyContent="space-between">
              <Grid item xs={6}>
                <Typography variant="body2" fontWeight={600} color="text.secondary">
                  Создан
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" fontWeight={600} color="text.secondary">
                  {formatDateSwaggerZ(shipment.documentDate)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mb={2} flexDirection="column">
              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    Отгружен
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    {shipment.shipmentDate} {shipment.shipmentTime}
                  </Typography>
                </Grid>
              </Grid>
              {shipment.bbNumber && (
                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Номер УПД
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      {shipment.bbNumber}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {shipment.bbCorrNumber && (
                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Номер УКД
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      {shipment.bbCorrNumber}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item md={7} xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container mb={2} flexDirection="column">
                  <Grid container justifyContent="space-between" mb={2}>
                    <Grid item xs={6}>
                      <Typography fontWeight={700} color="textPrimary">
                        Сумма отгрузки
                      </Typography>
                    </Grid>
                    <Grid item xs={6} onMouseEnter={() => setTooltipType('totalCost')} onMouseLeave={() => setTooltipType('')}>
                      <LightTooltip
                        placement="right"
                        open={tooltipType === 'totalCost'}
                        title={
                          !isNaN(totalCostOld) && !isNaN(totalCost) && totalCostOld !== totalCost ? (
                            <Box p={0.6}>
                              <Typography variant="subtitle2" component="span" fontWeight={500}>
                                Старое значение: {renderPriceCur(totalCostOld, '₽')}
                              </Typography>
                            </Box>
                          ) : undefined
                        }
                        arrow
                      >
                        <Typography
                          fontWeight={700}
                          color="textPrimary"
                          sx={
                            !isNaN(totalCostOld) && !isNaN(totalCost) && totalCostOld !== totalCost
                              ? { backgroundColor: ColorChangedPropertyDeal, px: 0.5, borderRadius: '5px', display: 'inline-block' }
                              : {}
                          }
                        >
                          {renderPriceCur(totalCost, '₽')}
                        </Typography>
                      </LightTooltip>
                    </Grid>
                  </Grid>

                  <Grid container mb={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Общий вес
                      </Typography>
                    </Grid>
                    <Grid item xs={6} onMouseEnter={() => setTooltipType('quantityTneReduce')} onMouseLeave={() => setTooltipType('')}>
                      <LightTooltip
                        placement="right"
                        open={tooltipType === 'quantityTneReduce'}
                        title={
                          !isNaN(quantityTheReduceOld) && !isNaN(quantityTneReduce) && quantityTheReduceOld !== quantityTneReduce ? (
                            <Box p={0.6}>
                              <Typography variant="subtitle2" component="span" fontWeight={500}>
                                Старое значение: {formatNumber3(quantityTheReduceOld)} т
                              </Typography>
                            </Box>
                          ) : undefined
                        }
                        arrow
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={
                            !isNaN(quantityTheReduceOld) && !isNaN(quantityTneReduce) && quantityTheReduceOld !== quantityTneReduce
                              ? {
                                  backgroundColor: ColorChangedPropertyDeal,
                                  px: 0.5,
                                  borderRadius: '5px',
                                  display: 'inline-block',
                                }
                              : {}
                          }
                        >
                          {formatNumber3(quantityTneReduce)} т
                        </Typography>
                      </LightTooltip>
                    </Grid>
                  </Grid>
                  {shipment.paymentSummary && (
                    <>
                      <Grid container mb={1} justifyContent="space-between">
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            Статус оплаты
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container alignItems={'center'}>
                            <MxLabel color={paymentStateColor(shipment.paymentSummary?.paymentStateCode)}>
                              {shipment.paymentSummary?.paymentStateTitle}
                            </MxLabel>
                            {shipmentsListStore ? (
                              shipmentsListStore.request?.paymentStateCodes?.includes(shipment.paymentSummary?.paymentStateCode) ? (
                                <Box>
                                  <IconButton
                                    size={'small'}
                                    onClick={() => handleRemovePaymentState(shipment.paymentSummary?.paymentStateCode)}
                                    title={`Убрать фильтрацию по статусу отгрузки ${shipment.paymentSummary?.paymentStateTitle || ''}`}
                                  >
                                    <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Box>
                              ) : (
                                <Box>
                                  <IconButton
                                    size={'small'}
                                    onClick={() => handleChangeFilterByPaymentStatus(shipment.paymentSummary?.paymentStateCode)}
                                    title={`Найти отгрузки по статусу оплаты ${shipment.paymentSummary?.paymentStateTitle || ''}`}
                                  >
                                    <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Box>
                              )
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
