import React, { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useAuthStore, useRootStore } from '../../../store/MobxStoreProvider';
import { DealItemStore } from '../../../store/DealItemStore';
import useAgreements from '../Hooks/useAgreements';
import { DealUpdatesPosition } from '../../../api/marketx';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import { MsgType } from '../../../store/Global/WebSocketStore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CustomSelectNew } from 'src/views/deals/components/CustomSelectNew';
import { IconButton } from '@mui/material';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

interface AgreementSelect {
  clientCode: string;
  currentCode?: string | null;
  dealStore: DealItemStore;
  onPositionAddDialogClick: (categoryCode?: string, agreementCode?: string) => void;
  onSelectTab: (v: number) => void;
  selectedTab: number;
}

const AgreementCustomSelect: FC<AgreementSelect> = observer(
  ({ clientCode, currentCode, dealStore, onPositionAddDialogClick, onSelectTab, selectedTab }): JSX.Element => {
    const matches = useMediaQuery('(min-width:818px)');
    const [selectedAgreementCode, setSelectedAgreementCode] = useState(currentCode);
    const [isLoadingAgreement, setIsLoadingAgreement] = useState(false);
    const { agreementsCodes, handleChangeCurrentAgreement, currentAgreement, agreementPosition, agreementsStore } = useAgreements(
      clientCode,
      dealStore
    );

    const rootStore = useRootStore();
    const auth = useAuthStore();
    const { profile } = auth;
    const { enqueueSnackbar } = useSnackbar();
    const snackbarStore = rootStore.getSnackbar();
    const eventMgr = rootStore.getEventManager();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const canSeeBtn = matches ? true : selectedTab === 5;
    const handleClick = (event): void => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
      setAnchorEl(null);
    };

    const onChangeAgreement = useCallback(
      async (value: string, reset = false) => {
        setIsLoadingAgreement(true);
        setSelectedAgreementCode(value);
        handleChangeCurrentAgreement(value);

        dealStore.apiStore
          .apiClientDeal()
          .dealsItemSave(dealStore?.dealCode, {
            code: dealStore?.dealCode,
            // @ts-ignore
            frontCode: profile.extra?.userCode,
            source: {
              itemCode: value,
              kind: 'agreement',
            },
            updates: reset
              ? {
                  agreementReset: true,
                  positions: null,
                }
              : {
                  agreementCode: value,
                  positions: null,
                },
          })
          .then(() => {
            onSelectTab(0);
            reset
              ? enqueueSnackbar(`Заявка отвязана от соглашения ${value}`, { variant: 'success' })
              : enqueueSnackbar(`Заявка привязана к соглашению ${value}`, { variant: 'success' });
            setIsLoadingAgreement(false);
            dealStore.reload(true);
          })
          .catch(r => {
            console.warn('handlePositionDeleteClick', r);
            setIsLoadingAgreement(false);
          });
      },
      [dealStore]
    );

    const handleAddAllProductFromAgreement = (): void => {
      if (selectedAgreementCode && !isLoadingAgreement) {
        setIsLoadingAgreement(true);
        const dealPositionSet = new Set(dealStore.deal.positions.map(item => item.agreementPositionCode));

        const updatePositions = new Array<DealUpdatesPosition>();

        agreementPosition?.forEach(p => {
          if (!dealPositionSet.has(p.code)) {
            updatePositions.push({
              agreementPositionCode: p.code,
              productCode: p.productCode,
              amount: p.baseQuantity,
              unitCode: p.baseUnitCode,
              unitCostType: p.unitCostType,
              bareUnitCost: p.calculatedBareUnitCost,
              tenderUnitCost: p.tenderUnitCost,
            });
          } else {
            dealStore.deal.positions.forEach(item => {
              if (item.agreementPositionCode === p.code && item.isArchive) {
                dealStore
                  .restorePosition({ code: item.code })
                  .then(() => {
                    snackbarStore.showSuccess(`Товар восстановлен "${p.product.title}"`);
                  })
                  .catch(r => console.warn('handlePositionRestoreClick', r));
              }
            });
          }
        });

        if (!updatePositions.length) {
          setIsLoadingAgreement(false);
          return;
        }

        //   @ts-ignore
        dealStore.addPositionsFromAgreement(updatePositions, selectedAgreementCode, profile.extra?.userCode).then(() => {
          snackbarStore.showSuccess(`Товары из соглашения ${selectedAgreementCode} добавлены в заявку ${dealStore.dealCode}`);
          eventMgr.processMessages({
            msgType: MsgType.SHOP_FRONT_DEAL_PRODUCT_ADDED,
            data: {
              dealCode: dealStore.dealCode,
            },
          });
        });
        setIsLoadingAgreement(false);
      }
    };

    const isDealInAgreement = dealStore.deal?.contractCostAgreements.length ? dealStore.deal?.contractCostAgreements[0] : null;

    const handleSelectProduct = (event): void => {
      event.stopPropagation();

      onPositionAddDialogClick('', currentAgreement.code);
    };

    if (!agreementsCodes.length && !currentAgreement?.code) {
      return null;
    }

    return (
      <Grid container alignItems="center">
        {!isDealInAgreement && (
          <>
            <Grid item>
              <CustomSelectNew
                label={'Выбрать соглашение'}
                options={agreementsCodes}
                isViewOnly={agreementsStore.isLoading}
                defaultValue={currentCode || ''}
                onChange={onChangeAgreement}
                sx={{
                  minWidth: '250px',
                }}
              />
            </Grid>

            <Grid item container ml={2} sx={{ width: '20px' }} alignSelf="center">
              {(isLoadingAgreement || agreementsStore.isLoading) && <CircularProgress size={20} />}
            </Grid>
          </>
        )}

        {!!isDealInAgreement && canSeeBtn && (
          <>
            {isLoadingAgreement ? (
              <Grid item container ml={1} sx={{ width: '22px' }} alignSelf="center">
                <CircularProgress size={20} />
              </Grid>
            ) : (
              <>
                <IconButton
                  color={'secondary'}
                  size="small"
                  title={'Отвязать Соглашение от заявки'}
                  onClick={() => onChangeAgreement(currentAgreement.code, true)}
                >
                  <LinkOffOutlinedIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </>
            )}

            <Grid item ml={1}>
              <Button size="small" endIcon={<KeyboardArrowDownIcon />} variant={'contained'} onClick={handleClick} color={'secondary'}>
                + Из соглашения
              </Button>
            </Grid>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                '& .MuiPaper-root': {
                  minWidth: 166,
                },
              }}
            >
              <MenuItem onClick={handleAddAllProductFromAgreement}>Все товары</MenuItem>
              <MenuItem onClick={handleSelectProduct}>Товар</MenuItem>
            </Menu>
          </>
        )}
      </Grid>
    );
  }
);

export default AgreementCustomSelect;
